import React from "react";

export const LogoIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50.000000pt"
      height="50.000000pt"
      viewBox="0 0 396.000000 380.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,380.000000) scale(0.10000,-0.10000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M1828 3689 c-352 -34 -692 -181 -965 -418 -281 -244 -486 -609 -554
      -987 -30 -167 -30 -430 -1 -596 97 -539 433 -990 917 -1233 853 -428 1880 -85
      2308 770 47 95 96 225 122 326 26 98 10 79 -25 -32 -138 -427 -479 -773 -903
      -914 -173 -57 -251 -70 -447 -70 -128 0 -199 5 -263 18 -297 60 -550 199 -760
      416 -158 165 -256 325 -326 536 -59 179 -75 292 -68 493 8 239 41 369 147 587
      370 752 1324 1015 2034 560 348 -222 589 -605 641 -1014 8 -64 16 -129 18
      -146 2 -16 5 -3 5 30 6 220 -64 503 -183 740 -315 632 -988 1002 -1697 934z"
        />
        <path
          d="M2175 3204 c-289 -44 -523 -156 -710 -340 -92 -91 -231 -274 -208
      -274 5 0 36 31 70 68 254 281 643 400 1008 307 492 -124 832 -607 775 -1099
      -43 -369 -256 -670 -585 -827 -165 -79 -239 -94 -445 -93 -160 0 -183 3 -265
      27 -153 46 -278 115 -422 233 -66 54 -30 1 60 -89 236 -235 532 -357 867 -357
      594 0 1095 414 1206 997 20 103 22 324 5 424 -85 498 -452 888 -941 1000 -83
      19 -344 34 -415 23z"
        />
        <path
          d="M1985 2436 c-116 -28 -232 -124 -283 -233 -34 -75 -43 -212 -18 -295
      35 -121 142 -234 259 -275 70 -24 214 -24 284 0 117 41 224 154 259 275 25 83
      16 220 -18 295 -83 179 -291 280 -483 233z"
        />
      </g>
    </svg>
  );
};
